import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    heading: {
        fontSize: "1.5em",
        fontFamily: "'Work Sans',sans-serif",
        fontWeight: "600",
    },
    question: {
        fontSize: "1.2em",
        fontFamily: "'Work Sans',sans-serif",
        fontWeight: "500",
    },
    answer: {
        fontSize: "1em",
        fontFamily: "'Work Sans',sans-serif",
        fontStyle: "italic",
        fontWeight: "500",
        background: "#f1f3f8",
        padding: "20px"
    },
    container: {
       width: "95%",
       fontSize: "1.1em",
       lineHeight: "1.4",
       fontFamily: "'Work Sans',sans-serif",
    },
    section: {
       position: "relative",
       // backgroundColor: "#fff"
    },
    sectionMain: {
       marginTop: "-170px",
       padding: "176px 0px 60px",
    }
 })

const FAQPage = (props) => {
    const { classes } = props;
    return (
        <Layout>
            <Helmet>
                <title>Managers App FAQs</title>
                <meta name="description" content="Some of the commonly asked questions about Managers App. Still have questions, we are here to help"></meta>
            </Helmet>
            <div className={"container"}>
                <div className={"targets"}>
                    <Grid container direction="row" spacing={0} alignItems="center" justify="left" >
                        <Grid item xs={12} className={classes.container}>
                            <Accordion defaultExpanded={true}>
                                <AccordionDetails>
                                    <Grid container direction="row" spacing={0} justify="left">
                                        <Grid item xs={12}>
                                            <div className={"title"} style={{ alignContent: "center" }}>
                                                <h1>Frequently Asked Questions</h1>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        What is Managers.App?
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        ManagersApp is a talent training and talent growth software that helps managers invest in their team members for better outcomes.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        How much does Managers.App cost?
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        You can try ManagersApp for free for 30 days without a credit card. Full access to all modules up to 6 team members is FREE.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        Can I cancel my subscription?
                                                </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        You can cancel your subscription at any time. You’ll only pay for the time your plan has been active.
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        Do you offer volume pricing?
                                                </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        Yes, we do. Please reach us at support@managers.app to discuss pricing.
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        Do you offer consulting services?
                                                </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        Yes, we provide consulting services in all areas of Engineering Management. Email us at support@managers.app to discuss how we can work together.
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        Does Managers.App integrate with 3rd party apps?
                                                </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        We are working on 3rd party app integration, which app did you have in mind? Let us know via support@managers.app please.
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion elevation="0">
                                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                                    <Typography className={classes.question}>
                                                        How can I share a suggestion or an idea with Managers.App?
                                                </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography className={classes.answer}>
                                                        We would love to hear your suggestions and ideas! Please drop us a line at support@managers.app to start
                                                        the conversation.
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Layout>
    )
}

export default withStyles(styles, { withTheme: true })(FAQPage)
